import { Button, Col, Divider, Row, Typography } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { CheckBoxField, NegativeNumberField } from 'src/components/form';
import { FormValue } from '../../useForm';
import useProfitMarginSetting from 'src/queries/useProfitMarginSetting';
import { formatCurrency, handleUnitPrices } from 'src/utils/helper';
import SelectCurrencyField from 'src/components/form/SelectCurrencyField';
import { UnitPrices } from 'src/enums/profitMargin';
import useSelectRow from 'src/hooks/useSelectRow';
import CuttingMethodSelect from './CuttingMethodSelect';
import SpecifyUnitLabel from '../SpecifyUnitLabel';

type Props = {
  cuttingMethodFieldArray: UseFieldArrayReturn<
    FormValue,
    'cutting_method_setting',
    'id'
  >;
  formInfo: UseFormReturn<FormValue, any>;
  setHasErrorTaniType: React.Dispatch<React.SetStateAction<boolean>>;
  dataProductNames?: {
    label: string;
    value: string;
    type: string;
  }[];
  queryProductNames?: UseQueryResult<
    AxiosResponse<Response.ProductNames, any>,
    AxiosError<Response.Error, any>
  >;
  setProductKeyword?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const CuttingMethodSetting: React.FC<Props> = ({
  cuttingMethodFieldArray,
  formInfo,
  setHasErrorTaniType,
  dataProductNames,
  queryProductNames,
  setProductKeyword,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
  } = formInfo;
  const { fields, append, remove } = cuttingMethodFieldArray;
  const [errorsSetting, setErrorsSetting] = useState<string[]>([]);
  const [methodKeyword, setMethodKeyword] = useState<string>();
  const [unitPrice, setUnitPrice] = useState<string>();
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState<number>();

  const {
    dataMachiningMethods,
    dataUnitPrices,
    deleteProductNameAndMethod,
    kokyakuCd,
    queryMachiningMethods,
    checkExistCurrency,
  } = useProfitMarginSetting({
    methodKeyword: methodKeyword,
    unitPrice: unitPrice,
  });

  const {
    handleSelectRow,
    selectedRecord,
    selectedSeqno,
    setSelectedRecord,
    setSelectedSeqno,
  } = useSelectRow();

  const handleAddRow = () => {
    append({
      seqno: undefined,
      tani_type: UnitPrices.PERCENT,
      riekrate1: null,
      riekrate2: null,
      riekrate3: null,
      riekrate4: null,
      product: '',
      processing_method: '',
      checkbox: false,
    });
  };

  const handleSelectUnitPrice = (value, index) => {
    setValue(`cutting_method_setting.${index}.riekrate1`, null);
    setValue(`cutting_method_setting.${index}.riekrate2`, null);
    setValue(`cutting_method_setting.${index}.riekrate3`, null);
    setValue(`cutting_method_setting.${index}.riekrate4`, null);
    if (value !== UnitPrices.PERCENT) {
      setSelectedCurrencyIndex(index);
      setUnitPrice(value);
    } else {
      clearErrors(`cutting_method_setting.${index}.tani_type`);
    }
  };

  useEffect(() => {
    let newArr: string[] = [];
    errors?.cutting_method_setting?.map((item) => {
      if (item) {
        Object.values(item).map((mess) => {
          mess?.message && newArr.push(mess.message);
        });
      }
    });
    const uniqueErrors = [...new Set(newArr)];
    setErrorsSetting(uniqueErrors);
  }, [errors?.cutting_method_setting]);

  useEffect(() => {
    if (
      checkExistCurrency?.data?.data?.item !== undefined &&
      selectedCurrencyIndex !== undefined
    ) {
      if (checkExistCurrency?.data?.data?.item === false) {
        setError(`cutting_method_setting.${selectedCurrencyIndex}.tani_type`, {
          type: 'custom',
          message: t('message.E000067'),
        });
      } else if (checkExistCurrency?.data?.data?.item === true) {
        clearErrors(
          `cutting_method_setting.${selectedCurrencyIndex}.tani_type`,
        );
      }
    }
  }, [checkExistCurrency?.data?.data?.item, selectedCurrencyIndex]);

  const handleRemoveRow = () => {
    selectedRecord?.map((item) => {
      setValue(`cutting_method_setting.${item}.checkbox`, false);
      clearErrors(`cutting_method_setting.${item}`);
    });
    remove(selectedRecord);
    if (kokyakuCd && selectedSeqno.length > 0) {
      const payloadDelete = {
        seqnos: selectedSeqno,
        kokyakuCd: kokyakuCd,
      };
      deleteProductNameAndMethod.mutate(payloadDelete);
      setSelectedSeqno([]);
    }
    setSelectedRecord([]);
  };

  useEffect(() => {
    setHasErrorTaniType(errorsSetting.includes(t('message.E000067')));
  }, [errorsSetting]);

  const handleShowNameSymbol = (index) => {
    const taniType = watch(`cutting_method_setting.${index}.tani_type`);
    return handleUnitPrices(taniType);
  };

  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Row
          className={classNames('setting_input', {
            new: !item.product && !item.processing_method,
          })}
          key={item.id}
        >
          <React.Fragment>
            <Col xs={2} md={1} xl={1}>
              <CheckBoxField<FormValue>
                controller={{
                  name: `cutting_method_setting.${index}.checkbox`,
                  control: control,
                }}
                checkboxProps={{
                  onClick: (event) => handleSelectRow(event, index, item.seqno),
                }}
              />
            </Col>
            <CuttingMethodSelect
              dataMachiningMethods={dataMachiningMethods}
              formInfo={formInfo}
              index={index}
              item={item}
              queryMachiningMethods={queryMachiningMethods}
              queryProductNames={queryProductNames}
              setMethodKeyword={setMethodKeyword}
              dataProductNames={dataProductNames}
              setProductKeyword={setProductKeyword}
            />
          </React.Fragment>
          <Col xs={24} className="new_divider mobile">
            <Divider />
          </Col>
          <Col xs={24} md={3} xl={2} className="setting_input_select">
            <SelectCurrencyField<FormValue>
              controller={{
                control,
                name: `cutting_method_setting.${index}.tani_type`,
                defaultValue: item.tani_type,
              }}
              selectProps={{
                options: formatCurrency(dataUnitPrices),
                onSelect: (value) => handleSelectUnitPrice(value, index),
              }}
              label={<SpecifyUnitLabel />}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              className="select_unit_price"
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `cutting_method_setting.${index}.riekrate1`,
              }}
              label={t('profit_margin_setting.0_to_1kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`cutting_method_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `cutting_method_setting.${index}.riekrate2`,
              }}
              label={t('profit_margin_setting.1_to_10kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`cutting_method_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `cutting_method_setting.${index}.riekrate3`,
              }}
              label={t('profit_margin_setting.10_to_100kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`cutting_method_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `cutting_method_setting.${index}.riekrate4`,
              }}
              label={t('profit_margin_setting.more_than_100kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`cutting_method_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
        </Row>
      ))}
      {errorsSetting &&
        errorsSetting.map((error, index) => (
          <Row key={index}>
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {error}
            </Typography.Text>
          </Row>
        ))}
      <Row className="setting_action">
        <Button
          className="setting_action_add"
          type="primary"
          onClick={handleAddRow}
          icon={<PlusSquareOutlined />}
        >
          {t('profit_margin_setting.add_line')}
        </Button>
        <Button
          className="setting_action_delete"
          type="primary"
          icon={<DeleteOutlined />}
          onClick={handleRemoveRow}
        >
          {t('profit_margin_setting.delete')}
        </Button>
      </Row>
    </React.Fragment>
  );
};

export default CuttingMethodSetting;
